@mixin respond($media) {
  @if $media == 'xs' {
      @media only screen and (max-width: $xs - 1px) { @content; }
  } @else if $media == 'gt-xs' {
      @media only screen and (min-width: $xs) { @content; }
  } @else if $media == 'till-sm' {
      @media only screen and (max-width: $sm - 1px) { @content; }
  } @else if $media == 'sm' {
      @media only screen and (min-width: $xs) and (max-width: $sm - 1px) { @content; }
  } @else if $media == 'gt-sm' {
      @media only screen and (min-width: $sm) { @content; }
  } @else if $media == 'till-md' {
      @media only screen and (max-width: $md - 1px) { @content; }
  } @else if $media == 'md' {
      @media only screen and (min-width: $sm) and (max-width: $md - 1px) { @content; }
  } @else if $media == 'gt-md' {
      @media only screen and (min-width: $md) { @content; }
  } @else if $media == 'lg' {
      @media only screen and (min-width: $md) and (max-width: $lg - 1px) { @content; }
  } @else if $media == 'gt-lg' {
      @media only screen and (min-width: $lg) { @content; }
  } @else if $media == 'xl' {
      @media only screen and (min-width: $lg) { @content; }
  }
}

@function headings($from: 1, $to: 6) {
  @if $from == $to {
    @return 'h#{$from}';
  } @else {
    @return 'h#{$from},' + headings($from+1, $to);
  }
}

@mixin font($family, $weight, $size) {
  font-family: $family;
  font-weight: $weight;
  font-size: $size;
}

@mixin bg-cover($size) {
  background-size: $size;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin backdrop($color, $alpha) {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color, $alpha);
  }
}
