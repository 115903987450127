%btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.6rem;
  cursor: pointer;
  transition: background-color .3s ease-in-out,
              color .3s ease-in-out;
}

.btn-box--white {
  @extend %btn;
  background-color: $white;
  color: $black;
  padding: 12px 24px;
  font-family: $font-logo;
  font-weight: bold;
  letter-spacing: 3px;

  &:hover {
    background-color: $black;
    color: $white;
  }
}

.btn-outline--white {
  @extend .btn-box--white;
  border: 1px solid $black;
  background-color: transparent;

  &:hover {
    background-color: $black;
    color: $white;
  }
}


.main-menu-burger {
  width: 30px;
  height: 20px;
  position: relative;
  background: transparent;
  border: 0;
  outline: none;
  transform: rotate(0deg);
  transition: transform .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    height: 4px;
    width: 50%;
    position: absolute;
    background: $black;
    transform: rotate(0deg);
    transition: transform .25s ease-in-out;
    opacity: 1;
  }

  span:nth-child(even) {
    left: 50%;
  }

  span:nth-child(odd) {
    left: 0;
  }

  span:nth-child(1),
  span:nth-child(2) {
    top: 0;
  }

  span:nth-child(3),
  span:nth-child(4) {
    top: 9px;
  }

  span:nth-child(5),
  span:nth-child(6) {
    top: 18px;
  }

  &.open {
    span:nth-child(1),
    span:nth-child(6) {
      transform: rotate(45deg);
    }

    span:nth-child(2),
    span:nth-child(5) {
      transform: rotate(-45deg);
    }

    span:nth-child(1) {
      left: 3px;
      top: 3px;
    }

    span:nth-child(2) {
      left: calc(50% - 3px);
      top: 3px;
    }

    span:nth-child(3) {
      left: -50%;
      opacity: 0;
    }

    span:nth-child(4) {
      left: 100%;
      opacity: 0;
    }

    span:nth-child(5) {
      left: 3px;
      top: 12px;
    }

    span:nth-child(6) {
      left: calc(50% - 3px);
      top: 12px;
    }
  }
}
