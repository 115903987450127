// sass-lint:disable no-css-comments
/**
 * @license
 * MyFonts Webfont Build ID 3769323, 2019-06-03T07:00:29-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Nexa-Bold by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/bold/
 * Copyright: Copyright \(c\) 2012 by Svet Simov. All rights reserved.
 *
 * Webfont: Nexa-Light by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/nexa/light/
 * Copyright: Modern and elegant sans serif font.
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3769323
 * Licensed pageviews: 10,000
 *
 * © 2019 MyFonts Inc
*/


// sass-lint:disable no-url-domains
// sass-lint:disable no-url-protocols
@import url('//hello.myfonts.net/count/3983eb');
// sass-lint:enable no-url-domains
// sass-lint:enable no-url-protocols
// sass-lint:enable no-css-comments

@font-face {
  font-family: 'Nexa';
  src: url('/assets/fonts/3983EB_0_0.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/3983EB_0_0.woff2') format('woff2'),
       url('/assets/fonts/3983EB_0_0.woff') format('woff'),
       url('/assets/fonts/3983EB_0_0.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Nexa';
  src: url('/assets/fonts/3983EB_1_0.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/3983EB_1_0.woff2') format('woff2'),
       url('/assets/fonts/3983EB_1_0.woff') format('woff'),
       url('/assets/fonts/3983EB_1_0.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}


@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/OpenSans-Light.woff2') format('woff2'),
       url('/assets/fonts/OpenSans-Light.woff') format('woff'),
       url('/assets/fonts/OpenSans-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/OpenSans.woff2') format('woff2'),
       url('/assets/fonts/OpenSans.woff') format('woff'),
       url('/assets/fonts/OpenSans.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/OpenSans-Italic.woff2') format('woff2'),
       url('/assets/fonts/OpenSans-Italic.woff') format('woff'),
       url('/assets/fonts/OpenSans-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('/assets/fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
       url('/assets/fonts/OpenSans-Bold.woff2') format('woff2'),
       url('/assets/fonts/OpenSans-Bold.woff') format('woff'),
       url('/assets/fonts/OpenSans-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
